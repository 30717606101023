import { Text } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

const Title = styled(Text).attrs(props => ({
  bold: { xs: true },
  variant: 'title100',
  ...props,
}))(
  ({ theme: { fontWeight, media, spacing } }) => css`
    ${media.sm} {
      font-size: ${spacing(6)};
      font-weight: ${fontWeight.semibold};
      line-height: ${spacing(7.25)};
    }
  `,
)

export default Title
